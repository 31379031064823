import React from "react"
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import HaveQuestions from "../components/haveQuestions/haveQuestions"
import Hero from "../components/hero/hero"
import CircleCta from "../components/circleCta/circleCta"
import IconWithText from "../components/iconWithText/iconWithText"
import Card from "../components/card/card"
import ElitekLogo from "../images/logos/elitek-logo.svg"
import JevtanaLogo from "../images/logos/jevtana-logo.svg"
import SarclisaLogo from "../images/logos/sarclisa_patient_new.svg";
import AffordIcon from "../images/icons/icon-afford.svg"
import AccessIcon from "../images/icons/icon-access.svg"
import FormsIcon from "../images/icons/icon-forms.svg"
import pillsIcon from '../images/icons/icon-pills.svg'
import QuestionsIcon from "../images/icons/icon-questions.svg"
import "../styles/pages/pages.scss"
import BackToTop from "../components/backToTop/BackToTop"



const showVideoModal = () => {
  const modal = document.querySelector('#video-overlay');
  // showing the video modal
  modal.classList.remove('hide');
  document.querySelector('html').style.overflow = 'hidden';
}

const pageContent = () => (

    <Layout pageid="page-home" pageClass="patient-home">
      <SEO
      title="CareASSIST for Sanofi medications | Patient Site"
      keywords="CareASSIST, Sanofi, Patient Site"
      description="Info for patients on CareASSIST by Sanofi, including financial assistance, enrollment, and resource support"
      />
      
      <Hero
      headline={["CareASSIST", <sup className="sup-fs-26">&reg;</sup>]}
      copy="Helping eligible patients access their prescribed medications"
      heroImgClass="hero-img background-size-100"
      isExternalButton={false}
      btnLink="../pdfs/careassist-program-enrollment-form.pdf"
      btnText="Enroll"
      iconClass="enrollIcon"
      btnClass="light-blue download-btn mb-1 fs-16 enroll"
      videoBtn
      videoBtnID="patient-careassist-video"
      videoOnClick={showVideoModal}
      label={<>CareASSIST <br/>overview video</>}
      videoBtnClass="lighter-blue video-btn"
      // iconText="Contact your doctor to enroll in the CareASSIST program today!" enable this to bring back text with arrow
      iconText=""
      iconUrl="https://sanoficareassisthcp.iassist.com/"
      />
      <section className="content-section non-brand-homepage our-care">
      <h2>Our Care Managers are available to guide you through your treatment journey, and can help with:</h2>
        <div className="flex-section evenly m-b-0">
          <IconWithText 
          icon={AffordIcon}
          alt="Paying for your medication"
          text={<>Paying for your <br className="mobilebr"/>medication</>}
          link=""
          additionalClass="p-x-0"
          />
          <IconWithText 
          icon={pillsIcon}
          alt="Getting your medication"
          text={<>Getting your <br className="mobilebr"/>medication</>}
          link=""
          additionalClass="p-x-0"
          />
          <IconWithText
          icon={AccessIcon}
          alt="Resources and additional support"
          text={<>Resources and <br className="mobilebr"/>additional support</>}
          link=""
          additionalClass="p-x-0"
          />
        </div>
      </section>
      <section className="select-treatment">
        <h2>Select a medication to get started</h2>
        <div className="flex-section evenly">
        <CircleCta
          class="elitek"
          img={ElitekLogo}
          imgAlt="Elitek Logo"
          piInfo={<p>Please see full <a id="patient-circlecta-elitek-pi" href="https://products.sanofi.us/elitek/elitek.html" target="_blank" rel="noreferrer">Prescribing&nbsp;Information</a>, including Boxed WARNING.</p>}
          btnLink="/elitek"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="elitek"
          linkId="patient-circlecta-elitek"
          />
          <CircleCta
          class="jevtana"
          img={JevtanaLogo}
          imgAlt="Jevtana Logo"
          piInfo={<p>Please see full <a id="patient-circlecta-jevtana-pi" href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank" rel="noreferrer">Prescribing&nbsp;Information</a>, including Boxed WARNING, and <a id="patient-circlecta-jevtana-patient-info" href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21" target="_blank" rel="noreferrer">Patient Information</a>.</p>}
          btnLink="/jevtana"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="jevtana"
          linkId="patient-circlecta-jevtana"
          />
          <CircleCta
          class="sarclisa"
          img={SarclisaLogo}
          imgAlt="Sarclisa logo"
          piInfo={<p>Please see full <a id="patient-circlecta-sarclisa-pi" href="https://products.sanofi.us/sarclisa/sarclisa.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including <a id="patient-circlecta-sarclisa-patient-info" href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf" target="_blank" rel="noreferrer">Patient Information</a>.</p>}
          btnLink="/sarclisa"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="sarclisa"
          linkId="patient-circlecta-sarclisa"
          />
        </div> 
      </section>
      <section className="content-section non-brand-homepage" linkId="overview">        
        <h2>Useful resources</h2>
        <div className="flex-section between">
          <Card
          linkId="patient-home-patient-overview-flashcard" 
          cardTitle="CareASSIST patient overview flashcard"
          cardText="A brief brochure explaining our program"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
          linkClass="download"
          linkTitle="Download the Patient overview brochure"
          linkText=""
          />
          <Card
          linkId="patient-home-enrollment-form" 
          cardTitle="Enrollment form"
          cardText="Complete this form with your doctor to enroll in CareASSIST"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-program-enrollment-form.pdf"
          linkClass="download"
          linkTitle="Download the Enrollment Form"
          linkText=""
          />
          <Card
          linkId="patient-home-spanish-enrollment-form" 
          cardTitle="Spanish enrollment form"
          cardText="Spanish language enrollment form"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-spanish-enrollment-form.pdf"
          linkClass="download"
          linkTitle="View the Letter of medical necessity site"
          linkText=""
          />          
        </div>

        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <BackToTop/>      
    </Layout>

)

export default pageContent